body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* table #tabel_rab {
	border-collapse:collapse;
}

table #tabel_rab tr {
	border:none;
}

table #tabel_rab tr, td {
	border-collapse:collapse;
	border: 1px solid #e9ecef;
	padding-top:0;
	padding-bottom:0;
} */

/* #table_rab th:nth-child(even) {
  position: sticky;
} */

#tabel_rab tr:nth-child(even) {
	border: 1px solid #e9ecef;
}

#tabel_rab td:nth-child(even) {
	border: 1px solid #e9ecef;
}